import React, { useState } from "react";
import { graphql } from "gatsby";
import Reveal from "react-reveal";
import Slider from "react-slick";
// import ProgramImpact from 'components/ProgramImpact';
// import ProgramWhy from 'components/ProgramWhy';
// import ProgramSubPrograms from 'components/ProgramSubPrograms';
import JoinModule from "../components/JoinModule";
import Layout from "../components/layouts";
import SubProgram from "../components/SubProgram";
import { Parallax } from "react-parallax";
import { RichText } from "prismic-reactjs";
import { lightenDarkenColor, truncateText } from "../utils";

const HeaderSlice = ({ content, program }) => {
  const { primary } = content;
  const { hero_image } = primary;
  return (
    <Parallax className="page-header" bgImage={hero_image.url} strength={400}>
      <div className="page-header-parallax">
          <div className="page-header-overlay" />
          <h1 className="page-header-title">{program}</h1>
      </div>
    </Parallax>
  )
};

const IntroSlice = ({ content }) => {
  const { primary } = content;
  const { intro_description } = primary;
  return (
    <div className="program-body">
      <div className="program-body-inner">
        <div className="program-intro">
          <div className="program-intro-inner">
            <div>
              { RichText.render(intro_description) }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

const IconSlice = ({ content, themeColor, subProgramList }) => {
  const { primary: iconPrimary } = content;
  const { icon } = iconPrimary;
  const { primary } = themeColor;
  const { color } = primary;
  const subProgramsNav = subProgramList?.map((sub, i) => {
    const title = sub.subprogram?.body?.find(x => x.type === "title_slice").primary?.title[0]?.text;
    const handleScroll = (e) => {
      e.preventDefault();
      const elId = title.toLowerCase().replace(/\s+/g, '-');
      console.log(document.getElementById(elId).getBoundingClientRect().y)
      window.scrollTo(0, document.getElementById(elId).getBoundingClientRect().y);
    }
    return (
        <div className="btn primary small" key={i} onClick={(e) => handleScroll(e)}>
            <span>{title}</span>
        </div>
    );
  });
  return (
    <div className="program-body">
      <div className="program-body-inner">
        <div className="program-icon-container" style={{background: `linear-gradient(-220deg, ${color} 0%, ${lightenDarkenColor({col: color, amt: -35})} 100%)`}}>
            <div className="program-icon" style={{backgroundImage: `url(${icon.url})`}} />
        </div>
        {subProgramList && subProgramList.length && (
          <div className="program-nav-container">
            <div className="program-nav-inner">
                {subProgramsNav}
            </div>
        </div>
        )}
      </div>
    </div>
  )
};

const SubProgramSlice = ({ content, themeColor }) => (
  <div className="program-body">
    <div className="program-body-inner">
      <SubProgram subProgram={content} themeColor={themeColor.primary?.color} />
    </div>
  </div>
);

const ImpactSlice = ({ content, themeColor }) => {
  const { fields } = content;
  const { primary } = themeColor;
  const { color } = primary;
  return (
    <div className="program-body">
      <div className="program-body-inner">
          <div className="page-impact-container">
          <div className="page-impact-list">
            <h1>Impact</h1>
            <div className="page-impact-inner">
                <div className="page-impact-modules">
                    {fields.map(impact => {
                      const { icon, impact_result, impact_title } = impact;
                      return (
                        <Reveal effect="animated bounceIn" key={impact_title}>
                          <div className="page-impact">
                            <div className="page-impact-icon-container" style={{background: `linear-gradient(-220deg, ${color} 0%, ${lightenDarkenColor({col: color, amt: -35})} 100%)`}}>
                                <div className="page-impact-icon" style={{backgroundImage: `url(${icon.url})`}} />
                            </div>
                            <div className="page-impact-number">{impact_title}</div>
                            <div className="page-impact-desc">{impact_result}</div>
                          </div>
                      </Reveal>
                      )
                    })}
                </div>
            </div>
        </div>
          </div>
      </div>
    </div>
  )
};

const GallerySlice = ({ content }) => {
  const { fields } = content;

  const images = fields.map(gallery => (
    <Reveal key={gallery.url}>
      <div className="program-body-gallery">
        <img src={gallery.gallery.url} />
      </div>
    </Reveal>
  ));

  return (
    <div className="program-body">
      <div className="program-body-inner">
        <div className="program-gallery-container">
          <div className="home-section-title-container">
            <h1 className="home-section-title">Gallery</h1>
            <span></span>
          </div>
          <div className="program-body-inner-image-grid">
            {images}
          </div>
        </div>
      </div>
    </div>
  );
};

const SubProgramSlider = ({ content }) => {
  const { fields } = content;
  const actionsList = fields.map(field => {
    const { subprogram_slider } = field;
    const thumbnail = subprogram_slider?.body.find(x => x.type === "main_thumb_image_slice")?.primary?.thumbnail?.url || 
      subprogram_slider?.body.find(x => x.type === "hero_image_slice")?.primary?.hero_image?.url;
    const color = subprogram_slider?.body.find(x => x.type === "color" || x.type === "theme_color_slice")?.primary?.color;
    const icon = subprogram_slider?.body.find(x => x.type === "icon" || x.type === "icon_slice")?.primary?.icon?.url;
    const title = subprogram_slider?.body.find(x => x.type === "title_slice")?.primary?.title[0].text ||
      subprogram_slider.program_name;
    const description = subprogram_slider?.body.find(x => x.type === "description_slice")?.primary?.description ||
      subprogram_slider?.body.find(x => x.type === "intro_slice")?.primary?.intro_description;
    const link = subprogram_slider?.body.find(x => x.type === "direct_link")?.primary?.link ||
      subprogram_slider?._meta?.uid;


    const excerpt = truncateText({ input: description && description.length && description[0].text ? description[0].text : "", len: 25 });
    return (
      <div className="module" key={title}>
        <div className="module-shadow-container">
          <div className="thumb" style={{ backgroundImage: `url(${thumbnail})`}}>
            <div className="icon" style={{ background: color }}>
              <div className="icon-svg" style={{ backgroundImage: `url(${icon})`}} />
            </div>
          </div>
          <div className="inner">
            <h1>{title}</h1>
            <div>
              <p dangerouslySetInnerHTML={{ __html: excerpt }} />
            </div>
          </div>
          {link && (
            <a href={link}>
              <div className="btn primary small" style={{ fontSize: "1em" }}><span>Learn More</span></div>
            </a>
          )}
        </div>
      </div>
    )
  });
  return (
    <div className="program-body">
      <div className="program-body-inner">
        <div className="program-html-container">
          <div className="home-section-title-container">
            <h1 className="home-section-title">Actions</h1>
            <span></span>
          </div>
          <div className="modules-container no-padding">
            <div className="modules-container-inner flex">
              {actionsList}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const VideoSlice = ({ content }) => (
  <div className="subprogram-video-container">
    <div className="embed-container">
      <iframe src={`https://player.vimeo.com/video/${content?.primary?.vimeo_id}?background=1&autoplay=1&muted=1&loop=1&byline=0&title=0&controls=0&playsinline=1`} />
    </div>
  </div>
);

const RichGallery = ({ content }) => {
  const anchorTagWithoutHash = typeof window !== 'undefined'? window.location.hash.substring(1) : null;
  const { fields } = content;
  let activeGalleryIndex = 0;
  if (anchorTagWithoutHash) {
    activeGalleryIndex = fields.findIndex(x => x.rich_gallery.body[0].primary.gallery_title.toLowerCase() === anchorTagWithoutHash.replace(/-/g, " ").toLowerCase());
  }
  const [activeGallery, setActiveGallery] = useState(activeGalleryIndex);

  return (
    <div className="program-body">
      <div className="program-body-inner">
        <h1 style={{margin: "0.5em 50px", maxWidth: 1440}}>Created by you at POSTed Studio</h1>
        <div className="program-nav-container">
          <div className="program-nav-inner">
            {fields?.map((gallery, idx) => {
              const { body } = gallery?.rich_gallery;
              const galleryTitle = body.find(x => x.type === "title_slice").primary.gallery_title;

              return (            
                <div className="btn primary small" key={idx} onClick={() => setActiveGallery(idx)}>
                  <span>{galleryTitle}</span>
                </div>
              )
          })}
          </div>
          <div className="program-gallery-container">
            <div className="program-body-inner-image-grid">
              {fields[activeGallery]?.rich_gallery.body.find(x => x.type === "gallery").fields.map((gallery, idx) => {
                  const { url } = gallery.gallery_thumbnail;
                  const { gallery_description, purchase_link, download_link } = gallery;
                  return (
                    <div className="program-body-gallery">
                      <img src={url} />
                      <div className="program-body-gallery-desc-container">
                        { RichText.render(gallery_description) }
                          {download_link && (
                            <a className="btn primary small" style={{ height: 40 }} key={idx} href={download_link} target="_blank">
                              <span>Download</span>
                            </a>
                          )}
                          {purchase_link && (
                            <a className="btn primary small" key={idx} href={purchase_link} target="_blank">
                              <span>Download</span>
                            </a>
                          )}
                      </div>
                    </div>
                  );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RichSliderGallery = ({ content }) => {
  const { fields, primary } = content;
  console.log(content);
  const gallerySettings = {
    dots: true,
    infinite: true,
    arrows: false
  };
  return (
    <div className="program-body">
      <div className="program-body-inner">
            <div className="program-slider">
              <div className="program-slider-text">
              { RichText.render(primary.gallery_text) }
              </div>
              <div className="program-slider-gallery">
              {fields && fields.length ? (
                <Slider {...gallerySettings}>
                  {fields.map(image => {
                    const { gallery_image } = image;
                    return <div className="subprogram-image" style={{backgroundImage: `url("${gallery_image?.url}")`}} key={gallery_image?.url}>
                      <img src={gallery_image?.url} />
                    </div>
                  })}
              </Slider>
              ) : null}
              </div>
            </div>
      </div>
    </div>
  )
};

const Form = ({ content }) => {
  const { primary } = content;
  const { form_title, form_embed } = primary;

  return (
    <div className="signup-body" id="signup-form">
      <div className="form-container application-form-container">
        <div className="form-inner application-form-inner">
          <h1>{form_title}</h1>
          <div dangerouslySetInnerHTML={{ __html: form_embed }} />
        </div>
      </div>
    </div>
  )
};

const sliceComponents = {
  hero_image_slice: HeaderSlice,
  icon_slice: IconSlice,
  intro_slice: IntroSlice,
  subprogram_slice: SubProgramSlice,
  impace_slice: ImpactSlice,
  gallery: GallerySlice,
  subprogram_slider: SubProgramSlider,
  video: VideoSlice,
  repeatable_rich_gallery: RichGallery,
  rich_slider_gallery: RichSliderGallery,
  mailchimp_form: Form,
};

export default ({ data }) => {
  // Define the Post content returned from Prismic
  const doc = data.prismic.allPrograms.edges.slice(0,1).pop();
  if(!doc) return null;
  const programName = doc.node.program_name;
  const subProgramsList =  doc.node?.body?.find(x => x.type === "subprogram_slice")?.fields;
  const themeColor = doc.node?.body?.find(x => x.type === "theme_color_slice");
  return (
    <Layout>
      <div className="program-container">
        <div className="programs-container-inner">
          {doc.node?.body.map((slice, i) => {
            if (!slice?.type) return null;
            const SliceComponent =
              sliceComponents[slice.type];

              if (SliceComponent) {
                return (
                  <SliceComponent
                    content={slice}
                    key={slice.id || i}
                    program={programName}
                    themeColor={themeColor}
                    subProgramList={subProgramsList}
                  />
                );
              }

              return null;
          })}
          <JoinModule />
        </div>
      </div>
    </Layout>
  )
}

// Query for the Blog Post content in Prismic
export const query = graphql`
query ProgramQuery($uid: String) {
  prismic{
    allPrograms(uid: $uid) {
      edges {
        node {
          _meta {
            uid
          }
          body {
            ... on PRISMIC_ProgramBodyMailchimp_form {
              type
              primary {
                form_embed
                form_title
              }
            }
            ... on PRISMIC_ProgramBodyRepeatable_rich_gallery {
              type
              fields {
                rich_gallery {
                  ... on PRISMIC_Rich_image_galler {
                    body {
                      ... on PRISMIC_Rich_image_gallerBodyTitle_slice {
                        type
                        primary {
                          direct_link
                          gallery_title
                        }
                      }
                      ... on PRISMIC_Rich_image_gallerBodyGallery {
                        type
                        label
                        fields {
                          gallery_thumbnail
                          gallery_description
                          download_link
                          purchase_link
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PRISMIC_ProgramBodyHero_image_slice {
              type
              label
              primary {
                hero_image
              }
            }
            ... on PRISMIC_ProgramBodyIntro_slice {
              type
              label
              primary {
                intro_description
              }
            }
            ... on PRISMIC_ProgramBodyIcon_slice {
              type
              label
              primary {
                icon
              }
            }
            ... on PRISMIC_ProgramBodyGallery {
              type
              label
              fields {
                gallery
              }
            }
            ... on PRISMIC_ProgramBodyVideo {
              type
              label
              primary {
                vimeo_id
              }
            }
            ... on PRISMIC_ProgramBodySubprogram_slider {
              type
              label
              fields {
                subprogram_slider {
                  ... on PRISMIC_Program {
                    program_name
                    _linkType
                    _meta {
                      uid
                    }
                    body {
                      ... on PRISMIC_ProgramBodyHero_image_slice {
                        type
                        label
                        primary {
                          hero_image
                        }
                      }
                      ... on PRISMIC_ProgramBodyIntro_slice {
                        type
                        label
                        primary {
                          intro_description
                        }
                      }
                      ... on PRISMIC_ProgramBodyTheme_color_slice {
                        type
                        label
                        primary {
                          color
                        }
                      }
                      ... on PRISMIC_ProgramBodyIcon_slice {
                        type
                        label
                        primary {
                          icon
                        }
                      }
                    }
                  }
                  ... on PRISMIC_Subprogram {
                    _linkType
                    body {
                      ... on PRISMIC_SubprogramBodyTitle_slice {
                        type
                        label
                        primary {
                          title
                        }
                      }
                      ... on PRISMIC_SubprogramBodyDescription_slice {
                        type
                        label
                        primary {
                          description
                        }
                      }
                      ... on PRISMIC_SubprogramBodyMain_thumb_image_slice {
                        type
                        label
                        primary {
                          thumbnail
                        }
                      }
                      ... on PRISMIC_SubprogramBodyIcon {
                        type
                        label
                        primary {
                          icon
                        }
                      }
                      ... on PRISMIC_SubprogramBodyColor {
                        type
                        label
                        primary {
                          color
                        }
                      }
                      ... on PRISMIC_SubprogramBodyDirect_link {
                        type
                        label
                        primary {
                          link
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PRISMIC_ProgramBodySubprogram_slice {
              type
              label
              fields {
                subprogram {
                  ... on PRISMIC_Subprogram {
                    _linkType
                    body {
                      ... on PRISMIC_SubprogramBodyTitle_slice {
                        type
                        label
                        primary {
                          title
                        }
                      }
                      ... on PRISMIC_SubprogramBodyDescription_slice {
                        type
                        label
                        primary {
                          description
                        }
                      }
                      ... on PRISMIC_SubprogramBodyMain_thumb_image_slice {
                        type
                        label
                        primary {
                          thumbnail
                        }
                      }
                      ... on PRISMIC_SubprogramBodyGallery_slice {
                        type
                        label
                        fields {
                          gallery_image
                        }
                      }
                      ... on PRISMIC_SubprogramBodyVideo_slice {
                        type
                        label
                        primary {
                          video_embed
                        }
                      }
                      ... on PRISMIC_SubprogramBodyCustom_html_slice {
                        type
                        label
                        primary {
                          custom_html
                        }
                      }
                      ... on PRISMIC_SubprogramBodySteps_slice {
                        type
                        label
                        fields {
                          step_description
                          step_image
                          step_title
                        }
                      }
                      ... on PRISMIC_SubprogramBodyStep_gallery_slice {
                        type
                        label
                        fields {
                          gallery_description
                          gallery_image
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PRISMIC_ProgramBodyImpace_slice {
              type
              label
              fields {
                icon
                impact_result
                impact_title
              }
            }
            ... on PRISMIC_ProgramBodyTheme_color_slice {
              type
              label
              primary {
                color
              }
            }
            ... on PRISMIC_ProgramBodyRich_slider_gallery {
              type
              label
              fields {
                gallery_image
              }
              primary {
                gallery_text
              }
            }
          }
          program_name
        }
      }
    }
  }
}
`
